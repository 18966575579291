import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { grayscale, orange } from '../../../styles/colors'
import { orange as newOrange } from '../../../styles/newColors'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  h1, h2 {
    margin-bottom: 20px;
  }

  .gatsby-image-wrapper {
    max-width: 456px;
  }
`
export const InvestMercadoAmericano = styled.section`
  .btn {
    max-width: 456px;
  }
`
export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: 100vh;

  @media ${device.tablet} {
    min-height: 400px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .react-multi-carousel-track {
    padding-top: 10px;

    @media ${device.tablet} {
      padding: 0;
    }

    @media ${device.desktopXXL} {
      padding-bottom: 40px;
    }

    @media ${device.desktopXXXL} {
      padding-bottom: 0;
    }

    .react-multi-carousel-item {

      button.btn, a.btn {

        @media ${device.desktopLG} {
          height: 44px;
        }
      }
    }
  }
`

export const BoxCard = styled.div`
  border-bottom: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    border-bottom: none;
    .card-tablet {
      min-height: 196px;
    }
  }

  @media ${device.desktopLG} {
    .card-tablet {
      min-height: 350px;
    }
  }
`

export const VantagensDeInvestir = styled.section`

  background: ${grayscale['400']};

  ul {

    li {
      @media ${device.tablet} {
        float: left;
        width: 50%;
      }

      > div {
        align-items: center;
        background-color: RGBA(0, 0, 0, 0.1);
        border-radius: 50%;
        display: flex;
        float: left;
        justify-content: center;
        height: 38px;
        text-align: center;
        width: 38px;

        @media ${device.tablet} {
          height: 60px;
          width: 60px;
        }
      }
      p {
        padding-left: 53px;

        @media ${device.desktopXL} {
          padding-left: 85px;
        }
      }
    }
  }
`

export const SimplesPraVoce = styled.section`
  p {
    min-height: 65px;
  }

  .images {
    .gatsby-image-wrapper {
      box-shadow: -10px 10px 30px 0px rgb(0 0 0 / 20%);
      border-radius: 30px;

      @media ${device.tablet} {
        border-radius: 40px;
      }
      @media ${device.desktopLG} {
        border-radius: 30px;
      }
      @media ${device.desktopXXXL} {
        border-radius: 40px;
      }
    }
  }

  .react-multi-carousel-track {
    padding: 10px 0 20px;
  }
`

export const CardWork = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 17px 20px;
  border-radius: 8px;
  background: #F5F6FA;

  @media ${device.tablet} {
    align-items: center;
  }

  .image {
    margin-right: 20px;
  }

  .title, .paragraph {
    font-family: "Inter", Helvetica, sans-serif;
  }

  & + div {
    margin-top: 8px;
  }
`

export const BlogCarousel = styled.div`

  &.p-card {
    padding: 0;

    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px
    }
  }

  article {
    display: grid;
    min-height: 470px;
  }
`

export const WrapperPopUp = styled.div`
 font-family: 'Citrina', Helvetica, sans-serif;

  .orange {
    color: ${orange.extra};
  }

  .new-orange {
    color: ${newOrange.clay};
  }
`
export const ImageAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
