import IcArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import cardJson from '../../assets/data/_card'
import { Button, Card, Section } from './style'

type CardProps = {
  image: string;
  text: string;
  link: string;
  description?: string;
  alt: string;
}
const TiposDeInvestimento = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='tipos-de-investimento' className='bg-grayscale--100 py-5'>
      <div className='container py-xl-5'>
        <div className='row'>
          <div className='col-12 mb-lg-3 px-3'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500'>
              Tipos de investimento
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Encontre as melhores opções de investimento e conte com os nossos especialistas para aumentar os seus resultados.
            </p>
          </div>
        </div>
        <div className='col-12 px-0'>
          <DefaultCarousel
            sm={{ items: 1, partialVisibilityGutter: 20 }}
            md={{ items: 2 }}
            lg={{ items: 3 }}
            xl={{ items: 4 }}
            itemClass='px-1 px-md-2'
          >
            {
              cardJson.map((item: CardProps) => (
                <div key={item.text}>
                  <Card image={item.image} role='img' aria-label={item.alt}>
                    <div>
                      <h3 className='fs-20 lh-24 fs-lg-24 lh-lg-28 text-white fw-500 mb-2'>{item.text}</h3>
                      <p className='fs-16 lh-19 text-white fw-400'>{item.description}</p>
                    </div>
                    <Button
                      href={item.link}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          element_action: 'click button',
                          element_name: `Saiba mais - ${item.text}`,
                          section_name: `Tipos de investimento - ${item.text}`,
                          redirect_url: item.link,
                        })
                      }
                      }
                    >
                      Saiba mais
                      <IcArrowRight height={24} width={24} color='#ffffff' className='ml-2' />
                    </Button>
                  </Card>
                </div>
              ))
            }
          </DefaultCarousel>
        </div>
      </div>
    </Section>
  )
}

export default TiposDeInvestimento
