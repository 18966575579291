import { Link } from 'gatsby'
import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import Home from '@interco/icons/build-v4/orangeds/MD/home'
import ImageWebp from 'src/components/ImageWebp'
import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

import { WIDTH_MD } from 'src/styles/breakpoints'
import headerJson from '../../assets/data/hero.json'

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaInvestimentos }: { qrCodeContaKidsAberturaDeContasInvestimentos: { deeplink: string; qrcode: string }; qrCodeAberturaDeContaInvestimentos: { deeplink: string; qrcode: string } } = qrcodeInvestimentsData

const InvestimentosQueSimplificamSuaVida = () => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    element_action: 'click button',
    element_name: 'Quero conhecer',
    section_name: 'Investimentos que simplificam sua vida',
  }

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaInvestimentos.qrcode}
        asFilledLink={qrCodeAberturaDeContaInvestimentos.deeplink}
        dataLayer={dataLayer}
        customOrigin='LP Investimentos'
      />
    </Modal>
  )

  return (
    <section id='investimentos-que-simplificam-sua-vida' className='bg-white pb-4 pt-4 pb-md-0'>
      {cardsModal}
      <div className='container'>
        <div className='row justify-content-center justify-content-lg-between align-items-center'>
          <div className='col-12 col-md-6  order-md-last mb-md-0 mt-3 mt-md-0'>
            {
              width >= WIDTH_MD && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/investimentos-app-herobanner-1/image.webp'
                  arrayNumbers={[]}
                  arrayNumbersHeight={[]}
                  altDescription='App do Inter com os tipos de investimentos.'
                />
              )
            }
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='bread'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#6A6E81' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[0]}</p>
            </div>
            <h1 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-56 lh-xl-61 fw-700 text-grayscale--500 mb-3'>
              Investimentos no Brasil e exterior
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0'>
              Invista com segurança e gratuitamente em CDB a partir de R$1,00, além de Tesouro Direto, Fundos,
              Criptomoedas e Ações no Brasil e em mercados globais.
            </p>
            <div>
              {
                width < WIDTH_MD
                ? (
                  <a
                    title='Quero conhecer'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                    href={qrCodeAberturaDeContaInvestimentos.deeplink}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Investimentos no Brasil e exterior',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                        redirect_url: qrCodeAberturaDeContaInvestimentos.deeplink,
                      })
                    }}
                  > Quero conhecer
                  </a>
                  )
                : (
                  <button
                    title='Quero conhecer'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Investimentos no Brasil e exterior',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                      })
                    }}
                  >
                    Quero conhecer
                  </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InvestimentosQueSimplificamSuaVida
