import ImageWebp from 'src/components/ImageWebp'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: #FDF8EE;

  .tab-ul {
    justify-content: center;

    @media (min-width: ${breakpoints.lg}){
      justify-content: flex-start ;
    }
  }

  .tab-ul li {
    margin: 0;
  }

  .tab-ul li button{
    width: 104px;
    text-align: center;
    color: ${grayscale[400]} ;

    @media (min-width: ${breakpoints.md}){
      width: 141px;
    }
    &::after {
      height: 2px;
      border-radius: 2px;
      content: "";
      background-color: ${white};
      display: block;
      margin-top: 24px;
    }

    &.active{


      &::after {
        background-color: ${orange.extra};
      }
      h4 {
        color: ${grayscale[500]} !important;

      }
    }

    h4 {
      font-family: "Inter", Helvetica, sans-serif !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
`
export const Card = styled.div`
  background: ${white};
  border-radius: 16px;
  border: none;
  height: 67px;
  width: 100%;
  color: ${grayscale[500]} ;
  padding: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}){
    height: 96px;
  }


`

export const Button = styled.a`
  height: 48px;
  width: 100%;
  background: ${orange.extra};
  border-radius: 8px;
  border: none;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;


  &:focus, &:hover{
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.md}){
    width: 457px;
  }

`
export const Tag = styled.div`
  background: ${orange.extra} ;
  border: none;
  border-radius: 15px;
  height: 19px;
  width: 53px;
  color: ${white};
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImageContainer = styled(ImageWebp)`
  max-width: 100%;
  height: auto;

`
