import React, { useState, useEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import OpenPageModal from './OpenPageModal'

import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import {
  AParceriaQueVocePrecisa,
  AsMelhoresFerramentasParaTraders,
  CompreCriptomoedas,
  FAQ,
  GanhePontosLoop,
  InvestimentosNoExterior,
  InvestimentosQueSimplificamSuaVida,
  InvistaNasMaioresEmpresas,
  MeuPorquinho,
  OMelhorAppParaInvestir,
  OsMlehoresConteudosInvestimento,
  PrecisaDeAjuda,
  Simples,
  TiposDeInvestimento,
  VantagensParaQuemInveste,
} from './sections/_index'

import { Wrapper } from './style'

const InterInvestimentos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isWideScreen, setIsWideScreen ] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const dataLayer: IDataLayerParams = {
    section: 'dobra_15',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {isWideScreen ? <OpenPageModal /> : null}
        <InvestimentosQueSimplificamSuaVida />
        <TiposDeInvestimento />
        <InvestimentosNoExterior />
        <InvistaNasMaioresEmpresas />
        <VantagensParaQuemInveste />
        <MeuPorquinho />
        <CompreCriptomoedas />
        <GanhePontosLoop />
        <AsMelhoresFerramentasParaTraders />
        <AParceriaQueVocePrecisa />
        <OMelhorAppParaInvestir />
        <Simples />
        <OsMlehoresConteudosInvestimento />
        <PrecisaDeAjuda />
        <FAQ />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          operationalLink='https://inter.co/inter-dtvm/'
          section='dobra_16'
        />
      </Layout>
    </Wrapper>
  )
}

export default InterInvestimentos
